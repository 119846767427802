.footer-logo-container {
	padding: 60px 40px;
	text-align: center
}

.footer-logo {
	display: inline-block;
	width: 250px;
	height: 120px;
	background: url("/assets/images/Branding/bronze-optical-logo.svg") center center/contain no-repeat;
	text-indent: -9999px;
	@media (max-width: 1300px) {
		width: 166px;
		height: 80px;
	}
}

.footer-top {
	background-color: $gray-dark3;
	.footer-info-container {
		padding: 60px 40px;
		.footer-info-row {
			.footer-info-header {
				color: $brandcolor1;
				font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
				font-size: 0.75em;
				line-height: 1;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.3em;
				padding: 10px;
				border: 1px solid $gray-dark2;
				border-bottom: none;
			}
			.footer-info-cell {
				color: $white;
				font-size: 1.25em;
				font-weight: 300;
				line-height: 1.4;
				padding: 10px;
				border: 1px solid $gray-dark2;
				min-height: 106px;
				height: 100%;
				@media (max-width: 1430px) {
					min-height: 106px;
				}
				@media (max-width: 1198px) {
					min-height: 100%;
				}
				p {
					font-size: 1.0em;
					line-height: 1.4;
					margin-top: 0;
					margin-bottom: 0;
					b, strong {
						font-weight: 700;
					}
				}
				a {
					color: $white;
					text-decoration: none;
				}
			}
		}
	}
}

.footer-bottom {
	background-color: $gray-dark3;
	background-image: url("/stylesheets/webart/backgrounds/background-pattern-light-10.png");
	background-repeat: repeat;
	background-position: top;
	padding: 60px 20px;
	text-align: center;
	.footer-copyright {
		color: $gray-mid3;
		background-color: $gray-dark3;
		font-size: 14px;
		line-height: 1;
		font-weight: 300;
		padding: 14px 30px;
	}
}

.footer-social-icon {
	color: $brandcolor1;
	line-height: 1;
}

.footer-social-desc {
	display: none;
}