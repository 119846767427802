html {
	background-color: $gray-dark3;
}

body {
	position: relative;
	background-color: $white;
}

a:focus,
button:focus,
:link:focus,
:visited:focus {
	outline: none !important;
}

button:focus,
.btn:focus {
	box-shadow: initial !important;
}

.hide-vis {
	display: none;
}

.background-image-overlay {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	// background-size: cover;
	// background-repeat: no-repeat;
	background-position: center;
}

.jumbotron-fluid {
	position: relative;
	z-index: 1;
}

.col-md-border:not(:last-child) {
	@media (min-width: 992px) {
		border-right: 1px solid $gray-light1;
	}

	@media (max-width: 992px) {
		border-bottom: 1px solid $gray-light1;
	}
}

.col-md-border+.col-md-border {
	@media (min-width: 992px) {
		margin-left: -1px;
	}

	@media (max-width: 992px) {
		margin-top: -1px;
	}
}

thead.thead-branded {
	tr th {
		color: $gray-mid3;
		font-weight: 400;
		font-size: 22px;
		border-top: none;
		border-bottom: 2px solid $brandcolor1;

		@media (max-width: 992px) {
			font-size: 20px;
		}

		@media (max-width: 768px) {
			font-size: 18px;
		}
	}
}

tbody.tbody-branded {
	tr td {
		line-height: 1;
		border-top: 1px dotted $gray-light3;

		@media (max-width: 992px) {
			font-size: 14px;
		}

		@media (max-width: 768px) {
			font-size: 12px;
		}
	}
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(250, 168, 26, 0.1);
}
