.page-block-tabs {
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	overflow-x: hidden;
	li {
		position: relative;
		background: $white;
		border-width: 1px 1px 0 1px;
		border-color: $gray-light2;
		border-style: solid;
		.nav-link {
			border: none;
			padding: 10px 30px !important;
		}
		&:hover {
			.nav-link {
				border-bottom: none;
			}
		}
		&:first-child {
			margin-left: 40px;
			&::after {
				content: " ";
				position: absolute;
				display: block;
				right: calc(100% + 1px);
				bottom: 0;
				width: 100vw;
				height: 2px;
				background: $gray-light2;
			}
		}
		&:last-child::after {
			content: " ";
			position: absolute;
			display: block;
			left: calc(100% + 1px);
			bottom: 0;
			width: 100vw;
			height: 4px;
			background: $brandcolor1;
		}
	}

	li::before {
		display: none !important;
	}
}
