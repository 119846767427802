.btn {
	line-height: 100%;
	border-radius: 0px;
	font-size: 16px;
	padding: 15px 30px;
}

.btn-sm {
	font-size: 12px !important;
	padding: 15px 30px !important;
}

.btn-lg {
	font-size: 20px !important;
	padding: 20px 40px !important;
}

.btn-primary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: $brandcolor1;
	border: none;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-dark;
		border: none;
		&::after {
			-webkit-transition: transform 0.5s ease-out;
			-moz-transition: transform 0.5s ease-out;
			-ms-transition: transform 0.5s ease-out;
			-o-transition: transform 0.5s ease-out;
			transition: .5s ease-out;
			margin-left: 10px;
			opacity: 100%;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light;
		border: none;
		box-shadow: 0;
		outline: none;
	}
	&::after {
		display: inline-block;
		position: relative;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f105";
		margin-left: -5px;
		opacity: 0%;
	}
}

.btn-outline-primary {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: none;
	color: $gray-dark2;
	border: 2px solid $brandcolor1;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-dark;
		border: 2px solid $brandcolor1-dark;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light;
		border: 2px solid $brandcolor1-light;
		box-shadow: 0;
		outline: none;
	}
}

.btn-outline-light {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: none;
	color: $brandcolor1 !important;
	border: 2px solid $gray-mid3;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $gray-mid3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $gray-mid3;
		box-shadow: 0;
		outline: none;
	}
}

.btn-outline-dark {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .3em;
	background-color: none;
	color: $gray-dark2;
	border: 2px solid $gray-dark2;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
		.fa {
			color: $white !important;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
		box-shadow: 0;
		outline: none;
	}
}

.btn-dark {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .04em;
	background-color: $gray-dark3;
	color: $brandcolor1;
	border: 2px solid $gray-mid3;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $gray-dark2;
		border: 2px solid $gray-mid3;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $gray-mid3;
		box-shadow: 0;
		outline: none;
	}
}

.btn-light {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .3em;
	background-color: none;
	color: $gray-dark2;
	border: 2px solid $gray-dark2;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $white;
		border: 2px solid $gray-dark2;
		.fa {
			color: $white !important;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1;
		border: 2px solid $brandcolor1;
		box-shadow: 0;
		outline: none;
	}
}
