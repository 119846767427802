.entry-pages-services {
	.service-entry-row {
		padding: 30px 0;
		.service-entry-icon {
			padding: 0;
		}
		.service-entry-icon img,
		.service-entry-copy {
			border-color: $gray-light2;
			border-style: solid;
		}
		.service-entry-icon img {
			position: absolute;
			top: 0;
			right: 0;
			border-width: 1px 0 1px 1px;
			width: calc(100% - 10px);
			padding: 5px;
		}
		.service-entry-copy {
			border-width: 1px 0 0 1px;
			padding: 20px;
			hr.divider-left::before {
				top: -0.120em;
			}
		}
	}
}
