.nav-tabs {
	position: relative;
	z-index: 50;
}
.navbar-nav {
	border: 1px solid $gray-mid3;
	padding-top: 8px;
	transform: translateY(-28px);
	@media (max-width: 1300px) {
		transform: translateY(-8px);
	}
}

.nav-link {
	color: $white;
	font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-size: 12px;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	@media (max-width: 1300px) {
		font-size: 10px !important;
	}
	@media (max-width: 1130px) {
		font-size: 9px !important;
	}
}

.nav-link {
	color: $white;
	border: 1px solid rgba(255,255,255,0);
	padding: 0 30px 6px 30px !important;
}

.header-full {
	.nav-link {
		border-top: 4px solid $gray-dark2;
		border-bottom: 4px solid $gray-dark2;
	}
	.nav-item {
		border-right: 1px solid $gray-mid3;
	}
	.nav-item:last-child {
		border-right: none;
	}
}

.nav-item {
	margin: 0px;

	&:hover {
		.nav-link {
			color: $white;
			border-bottom: 4px solid $brandcolor1;
		}
	}
}

.navbar-toggler {
	border: 2px solid white !important;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav-down, .nav-up {
	.navbar-toggler {
		border: 2px solid $brandcolor1 !important;
	}
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,168,26,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

#header-menu, #header-menu-half {
	li {
		display: block;
		transition-duration: 0.5s;
		&:hover {
			cursor: default;
			&.pointer {
				cursor: pointer;
			}
		}
	}


	ul li ul {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		transition: all 0.5s ease-out;
		display: none;
		transform: translateX(-40px);
		a {
			color: $white;
			background-color: $gray-dark2;
			border: 1px solid $white;
			padding: 10px 30px !important;
		}
	}

	ul li:hover > ul,
	ul li ul:hover {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	ul li ul li {
		clear: both;
		width: 100%;
	}
}

@media (max-width: 991.9px) {
	#header-menu{
		margin: 20px 0 0 0;
		padding: 0;
		background-color: $gray-dark2;
		border: 1px solid $white;
		ul li ul {
			visibility: visible;
			opacity: 1;
			position: relative;
			display: block;
			transform: translateX(0px);
			padding-left: 20px;
		}
		.navbar-nav {
			border: none;
			li {
				a {
					color: $white;
					background: none;
					border: none;
					padding: 10px 30px !important;
				}
				li {
					border: none;
				}
			}
		}
	}
}
