#home-alerts-banner {
	margin-top: -3rem;
	padding: 20px;
	background: $brandcolor2;
	border: 1px solid $brandcolor2-light;
	.home-alerts-container {
		border: 2px solid $brandcolor2-light;
	}
	.home-alert {
		color: $white;
		padding: 0px 20px;
		p {
			line-height: 1.25;
		}
		a {
			color: $white;
			&:hover {
				color: $brandcolor1-light;
				text-decoration: none;
			}
			.home-alert-icon-more {
				color: $brandcolor1-light;
			}
		}
	}
	.home-alert + .home-alert {
		border-top: 2px solid $brandcolor2-light;
	}
}

#home-alerts-modal {
	backdrop-filter: blur(5px);

	.modal-content {
		height: auto;
		background: $brandcolor2;
		border-radius: 0;
		border: 1px solid $brandcolor2-light;
		color: $white;
		display: flex;
		flex-direction: column;

		.modal-header {
			position: relative;
			flex: 0 1;
			padding-bottom: 0;
			border-bottom: none;
		}

		.modal-body {
			margin: 0px 20px 20px 20px;
			border: 2px solid $brandcolor2-light;
			text-align: center;
			flex: 1 1 0;
			p {
				line-height: 1.25;
			}
		}

		#home-alerts-carousel {
			position: relative;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			.carousel-inner {
				padding: 20px;
				// top: 50%;
				// transform: translateY(-50%);
			}

			.carousel-item {
				
			}

			.carousel-control-prev-icon, .carousel-control-next-icon {
				width: 10px;
			}
		}

		h2 {
			color: $white;
			font-weight: 400;
			font-size: 2.5em;
		}

		.btn-home-alert {
			color: $white !important;
			border: 1px solid $brandcolor1;
			&:hover {
				color: $white;
			}
		}

		.close {
			color: $white;
			transform: translateY(-10px);
			&:hover {
				color: $brandcolor1-light;
				text-decoration: none;
			}
		}
	}
}
