.body, body { 
	font-family: proxima-nova, Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 500;
	font-style: normal;
	line-height: 1;
	color: $gray-dark2;
}

.body {
	ul {
		list-style: none; /* Remove default bullets */
		margin: 20px 0px 20px -5px;
	}

	ul li::before {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor1; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		display: inline-block; /* Needed to add space between the bullet and the text */
		width: 1.5em; /* Also needed for space (tweak if needed) */
		margin-left: -1.5em; /* Also needed for space (tweak if needed) */
		transform: translateY(4px);
	}

	ul li {
		line-height: 1.25;
		margin-bottom: 20px;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $gray-dark1 !important;
	}

	ul li a:hover {
		// color: $brandcolor1-light !important;
	}

	ol li {
		line-height: 1.25;
		margin-bottom: 20px;
	}
}

p {
	font-size: 18px;
	line-height: 2;
}

a {
	color: $brandcolor1-light;
	&:hover {
		color: $brandcolor1;
	}
}

h1 {
	font-weight: 300;
	font-size: 4em;
	line-height: 1;
}

h2 {
	font-weight: 700;
	font-size: 3em;
	line-height: 1;
	letter-spacing: -0.025em;
	color: $gray-dark1;
}

h3 {
	font-weight: 700;
	font-size: 2em;
	line-height: 1;
}

h4 {
	font-weight: 300;
	font-size: 1.5em;
	line-height: 1;
}

h5 {
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
}

h6 {
	font-weight: 700;
	font-size: 1em;
	line-height: 1;
	text-transform: uppercase;
}

.text-light, .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, {
	color: $white;
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-headline-text {
	margin-bottom: 10px;
}

h4.page-subheadline-text {
	margin-top: 10px;
}
