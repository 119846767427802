.accordion {
	border-top: 4px solid $brandcolor1;
	padding-bottom: 120px;
	.card  {
		background-color: $white;
		.card-header {
			background-color: $white;
			h2 button {
				color: $gray-dark1;
				font-size: 0.416666em;
				font-weight: 700;
				&:hover {
					text-decoration: none;
					.faq-open-button,
					.faq-open-button::after {
						background-color: $brandcolor1;
						color: $white;
					}
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					text-decoration: none;
				}
				.faq-arrow {
					display: block;
					float: left;
					color: $white;
					background-color: $brandcolor1;
					border-radius: 50%;
					font-size: 36px;
					padding: 8px;
					margin-right: 20px;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					@media (max-width: 767.9px) {
						font-size: 24px;
					}
				}
				&[aria-expanded="true"] {
					.faq-arrow {
						transform: rotate(180deg);
					}
					.faq-open-button {
						visibility: hidden;
					}
					.faq-open-button::after {
						visibility: visible;
					}
				}
				.faq-open-button,
				.faq-open-button::after {
					display: block;
					position: relative;
					float: right;
					color: $gray-dark2;
					font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
					font-size: 0.5555em;
					font-weight: 700;
					letter-spacing: 0.1em;
					text-transform: uppercase;
					white-space: nowrap;
					padding: 5px 20px;
					margin-left: 20px;
					border: 1px solid $brandcolor1;
					visibility: visible;
					@media (max-width: 767.9px) {
						display: none;
					}
				}
				.faq-open-button::after {
					content: "Hide Answer";
					position: absolute;
					top: -1px;
					right: -1px;
					float: none;
					font-size: 1em;
					visibility: hidden;
				}
			}
		}
		.card-body {
			background-color: $white;
			border-top: 1px solid $gray-light1;
			border-bottom: 4px solid $brandcolor1;
			padding: 40px 80px;
			p {
				font-size: 1.0em;
				font-weight: 300;
			}
			@media (max-width: 767.9px) {
				padding: .75rem 1.25rem;
			}
		}
	}
	@media (max-width: 767.9px) {
		.btn {
			padding: 0;
		}
	}
}
