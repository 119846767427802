.entry-teaser {
	background: none;
	padding: 20px;
	margin: 0;
	border: none;
	border-radius: 0;
	@media (max-width: 1200px) {
		padding: 35px;
	}
	@media (max-width: 992px) {
		padding: 30px;
	}
	@media (max-width: 768px) {
		padding: 25px;
	}
	@media (max-width: 480px) {
		padding: 20px;
	}
	.entry-card-body {
		border-bottom: 1px solid $gray-light3;
		border-left: 1px solid $gray-light3;
		border-right: 1px solid $gray-light3;
		padding: 20px;
	}
	.entry-teaser-thumb {
		width: 100%;
		height: auto;
		border: 1px solid $gray-light3;
	}
	h3.entry-teaser-headline,
	h3 a.entry-teaser-headline {
		font-size: 1.5em;
		font-weight: 550;
	}
	.entry-teaser-headline,
	a.entry-teaser-headline {
		padding-bottom: 5px;
		line-height: 0.9;
		color: $gray-dark2;
		letter-spacing: -0.025em;
	}
	a.entry-teaser-headline:hover {
		color: $gray-dark2;
		text-decoration: none;
	}
	.entry-teaser-copy {
		font-size: 14px;
		line-height: 1.7;
	}
	.entry-teaser-button {
		border-top: none;
		padding: 0;
		.entry-teaser-link {
			margin: 0;
			padding: 0 5px 0 20px;
			.entry-teaser-link-icon {
				font-size: 24px;
				color: $brandcolor1;
				margin: 0 0 0 15px;
				padding: 8px 0 10px 5px;
				border-left: 1px solid $gray-light3;
				&:hover {
					-webkit-transition: transform 0.5s ease-out;
					-moz-transition: transform 0.5s ease-out;
					-ms-transition: transform 0.5s ease-out;
					-o-transition: transform 0.5s ease-out;
					transition: .5s ease-out;
					color: $white;
					border-left: 1px solid $white;
				}
			}
		}
	}
}

.blog-headline {
	line-height: 1;
}

.blog-headline-text {
	line-height: 1;
	margin-bottom: 4px;
}

.blog-subheadline-text {
	line-height: 1;
	margin-top: 4px;
}

//Channel Entries Block
.channel-entries-more-link {
	position: relative;
	color: $brandcolor1;
	font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-size: 12px;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	&:hover {
		text-decoration: none;
	}
}
.channel-entries-more-link {
	position: absolute;
	bottom: 40px;
	right: 0;
	@media (max-width: 767.9px) {
		position: relative;
		bottom: auto;
		right: auto;
	}
}
.channel-entries-headline {
	font-weight: 400;
}
