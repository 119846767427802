.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
}

.carousel-overlay {
	background: linear-gradient(180deg, rgba(60,60,59,1) 0%, rgba(60,60,59,0.25) 25%, rgba(60,60,59,0.25) 75%, rgba(60,60,59,1) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.carousel-caption {
	position: absolute;
	bottom: 40%;
	left: 60px;
	transform: translateY(40%);
	width: 50%;
	h1 {
		margin: 0;
		text-shadow: rgba(26, 13, 0, 0.4) 0px 0px 12px;
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0px;
	}
	hr.caption-divider {
		overflow: visible; /* For IE */
		padding: 0;
		border: none;
		border-top: 1px solid $brandcolor1;
		color: $white;
		text-align: right;
	}
	.caption-body {
		padding-top: 0px;
	}

	@media (max-width: 767.9px) {
		margin-top: 80px;
		margin-left: 15px;
		margin-right: 15px;
		font-size: 0.5em;
		left: 0;
		right: 0;
		width: auto;
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 20px;
	background-image: none;
	border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 42px;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 42px;
}
