input {
	width: 100%;
	padding: 10px;
	border: 1px solid $gray-light3;
	border-radius: 6px;
}

textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid $gray-light3;
	border-radius: 6px;
}

.form-label-required {
	color: $brandcolor1-dark;
}

.form-check-input:not(:checked), .form-check-input:checked {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.btn-group-toggle {
	margin: 0px 20px;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $gray-dark2 !important;
	border: 1px solid $gray-light3 !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1 !important;
		border: 1px solid $gray-light3 !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light !important;
		border: 1px solid $gray-light3 !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active, 
.btn-outline-group:not(:disabled):not(.disabled).active, 
.show>.btn-outline-group.dropdown-toggle {
	color: $white !important;
  background-color: $gray-dark1 !important;
 }