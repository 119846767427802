.entry-pages-home {
	.entries-list-items-services {
		.col-copy {
			background-color: $gray-dark3;
			border: 1px solid $gray-dark1;
			padding: 30px;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			p {
				font-weight: 300;
				font-size: 1.5em;
				line-height: 1.2em;
			}
			hr {
				border-top: 1px solid $gray-dark1;
				margin: 0 -30px 10px 0;
			}
			@media (min-width: 768px) {
				-ms-flex: 0 0 75%;
				flex: 0 0 75%;
				max-width: 75%;
			}
			@media (min-width: 992px) {
				-ms-flex: 0 0 60%;
				flex: 0 0 60%;
				max-width: 60%;
			}
		}
		.col-entry {
			background-color: $gray-dark2;
			border: 1px solid $gray-dark1;
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			@media (min-width: 576px) {
				-ms-flex: 0 0 25%;
				flex: 0 0 25%;
				max-width: 25%;
			}
			@media (min-width: 992px) {
				-ms-flex: 0 0 20%;
				flex: 0 0 20%;
				max-width: 20%;
			}
		}
		.entry-teaser {
			padding: 0;

			.card-img-top {
				width: 100%;
				height: auto;
				max-width: 100%;
				max-height: none;
			}
			h3.entry-teaser-headline,
			h3 a.entry-teaser-headline {
				text-align: center;
				font-size: 1.25em;
				font-weight: 300;
				letter-spacing: -0.01em;
				color: $white;
				.text-light {
					color: $white;
				}
			}
			.entry-teaser-button {
				display: none;
			}
			.entry-teaser-thumb {
				border: none;
			}
		}
		.entry-card-body {
			border: none;
			border-top: 1px solid $gray-dark1;
		}
	}
	.entries-list-items-blog {
		.card-body {
			background-color: $white;
		}
		h3.entry-teaser-headline,
		h3 a.entry-teaser-headline {
			color: $gray-dark2;
			.text-light {
				color: $gray-dark2;
			}
		}
		.news-article-teaser {
			padding: 0px;
		}
	}
	.entries-list-items-products {
		.entry-teaser {
			.entry-teaser-button {
				display: none;
			}
		}
		.col-entry {
			padding: 0px;
		}
	}
	.container-home-callout-cards {
		.home-callout-card {
			position: relative;
			border: none;
			border-radius: 0;
			background-color: $gray-mid3;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			box-shadow: none !important;
			.card-outer-frame {
				border: 1px solid $gray-mid3;
				border-radius: 0;
				position: relative;
				padding: 20px;
				.card-body {
					position: relative;
					padding: 20px;
					color: $white;
					border: 2px solid $gray-mid3;
					padding: 20px;
					padding-bottom: 80px;
					.image-callout-headline  {
						color: white;
						font-size: 3.0em;
						font-family: "Avenir LT", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
						font-weight: 300;
						letter-spacing: 0em;
						margin-bottom: 20px;
						border-bottom: 1px solid $gray-dark2;
					}
					.image-callout-subheadline {
						color: $brandcolor1;
						text-transform: uppercase;
						font-size: 1.0em;
						font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
						font-weight: 700;
						letter-spacing: .3em;
					}
					h4 {
						color: $brandcolor1;
						text-transform: uppercase;
						font-size: 0.75em;
						font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
						font-weight: 700;
						letter-spacing: .3em;
					}
					p {
						font-size: 1.5em;
						font-weight: 300;
						a {
							color: #fff;
							text-decoration: none;
						}
					}
					&.small-copy p {
						font-size: 1.25em;
					}
					.footer-social-icon {
						font-size: 2.0em;
						margin-bottom: 20px;
					}
					> .btn {
						display: inline-block;
						position: absolute;
						bottom: 0px;
						right: 0px;
						margin-left: auto;
					}
					> .btn-dark {
						border: 2px solid $gray-mid3;
						border-right: none;
						border-bottom: none;
					}
				}
				@media (min-width: 992px) {
					height: 100%;
					.card-body {
						height: 100%;
					}
				}
				@media (max-width: 767.9px) {
					.card-body {
						font-size: 0.9em;
						> .btn {
							padding: 15px 10px;
							left: 0;
							border-left: none;
						}
					}
				}
				@media (max-width: 575.9px) {
					.card-body {
						font-size: 0.8em;
					}
				}
			}
		}
	}
}
