.page-title-back {
	background-color: $gray-dark2;
	position: relative;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top;
	background-attachment: fixed;
	z-index: 0;
}

.page-title-overlay {
	background: linear-gradient(180deg, rgba(60,60,59,1) 0%, rgba(60,60,59,0.25) 25%, rgba(60,60,59,0.25) 75%, rgba(60,60,59,1) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.page-title-container {
}

h1.page-title {
	display: inline-block;
	color: $white;
	margin: 0;
	padding: 0 20px 12px 0;
	border-bottom: 1px solid $brandcolor1;
}

.breadcrumbs {
	display: inline-block;
	font-family: "Century Gothic", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	color: $white;
	margin: 0;
	padding: 10px 0 0 0;
	.breadcrumb-first {
		border-top: 4px solid $brandcolor1;
		padding-top: 3px;
	}
	.breadcrumb-separator {
		color: $brandcolor1;
		font-weight: 700;
	}
	.breadcrumb-current, .breadcrumb-current a {
		color: $white;
	}
	a  {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $brandcolor1;
			text-decoration: none;
		}
	}
}