.header-full {
	background-color: $gray-dark2;
	background-image: url("/stylesheets/webart/backgrounds/header-pattern-top.png");
	background-repeat: repeat-x;
	background-position: top;
	position: sticky;
	z-index: 100;
	width: 100%;
	height: 80px;
	padding: 0px 40px;
}

.header-container {
	padding: 0;
}

.nav-up {
	// top: -117px;
	top: 0px;
}

.nav-down, .nav-up {
	height: 80px;
	padding: 0px 40px;
	.header-container {
		padding: 0px;
	}
	.nav-link {
		color: $gray-dark2;
	}
}

.navbar-brand {
	width: 250px;
	height: 120px;
	background: url("/assets/images/Branding/bronze-optical-logo.svg") center center/contain no-repeat;
	text-indent: -9999px;
	margin: 25px 10px 0 10px;
	@media (max-width: 1300px) {
		width: 166px;
		height: 80px;
	}
}
