.entry-pages-collections {
	.collection-item {
		min-height: 8rem;
		position: relative;
		h3, a {
			position: absolute;
		}
		h3 {
			background-color: rgba(180, 180, 180, 0.6);
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
		}
		a {
			background: rgba(180, 180, 180, 0.6);
		}
	}
}
.entry-type-products {
	.row-single-image {
		display: none;
	}
}
.product-thumb {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	&.product-thumb-lg {
		@include media-breakpoint-up(sm) {
			height: 100% !important;
		}
	}
	a {
		position: absolute;
		left: 0;
		bottom: 0;
		.product-full-link {
			position: absolute;
			bottom: 0;
			left: 0;
			overflow: hidden;
			flex-shrink: 2;
			transition: all 300ms ease-in-out;
			background: rgba(46, 46, 46, 0.0);
			.fa, .fas, .link-cta {
				position: relative;
				top: 0;
				transition: all 300ms ease-in-out;
				background: rgba(46, 46, 46, 1.0);
				z-index: 10;
			}
			.link-cta {
				display: inline-block;
				left: -200px;
				z-index: 5;
				text-transform: uppercase;
				font-size: 12px;
				padding: 0rem .5rem;
			}
		}
		&:hover {
			.product-full-link {
				background: rgba(46, 46, 46, 1.0);
				.fa, .fas {
					color: rgba(255, 255, 255, 1.0);
					background: rgba(214, 137, 6, 1.0);
				}
				.link-cta {
					left: 0px;
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	.col-sm-6.col-expand {
		flex: 0 0 52.5%;
		max-width: 52.5%;
	}

	.col-sm-6.col-contract {
		flex: 0 0 47.5%;
		max-width: 47.5%;
	}
}
