.contact-header {
	padding-top: 120px;
	text-align: center;	
}

.contact-form-container {
	padding-bottom: 120px;
}

.form-label {
	font-size: 18px;
}

.btn-contact-form {
	width: auto;
}

.contact-location-table {
	tr {
		vertical-align:top;
	}
	th {
		color: $brandcolor1;
		font-family: "Montserrat", Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
		font-size: 0.75em;
		line-height: 1;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.3em;
		padding: 10px;
		border: 1px solid $gray-mid1;
		border-bottom: none;
	}
	td {
		color: $gray-dark1;
		font-size: 1.25em;
		font-weight: 300;
		line-height: 1.4;
		padding: 10px;
		border: 1px solid $gray-mid1;
		min-height: 106px;
		height: 100%;
		@media (max-width: 1430px) {
			min-height: 106px;
		}
		@media (max-width: 1198px) {
			min-height: 100%;
		}
		p {
			font-size: 1.0em;
			line-height: 1.4;
			margin-top: 0;
			margin-bottom: 0;
			b, strong {
				font-weight: 700;
			}
		}
		a {
			color: $white;
			text-decoration: none;
		}
		.footer-social-desc {
			display: none;
		}
		.footer-social-icon {
			color: $gray-mid3;
			line-height: 1;
		}
	}
}