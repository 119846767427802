// Basic Colors
$white: #FFFFFF;
$black: #000000;

// Main Brand Color - Bronze Mango Mojito Color
$brandcolor1-light: #ffbc2b;
$brandcolor1: #df9a00;
$brandcolor1-dark: #c47600;

//Secondary Brand Colors
//Moss Color
$brandcolor2-light: #9c9c8e;
$brandcolor2: #787a68;
$brandcolor2-dark: #585e49;

//Coral Pink Color
$brandcolor3-light: #fbcec2;
$brandcolor3: #f9b9a9;
$brandcolor3-dark: #f8a393;

// Grays
$gray-light1: #f0f0eb;
$gray-light2: #d6d6d2;
$gray-light3: #bdbdb9;

$gray-mid1: #a3a3a0;
$gray-mid2: #8a8a87;
$gray-mid3: #70706e;

$gray-dark1: #575755;
$gray-dark2: #3c3c3b;
$gray-dark3: #242423;
